import axios from 'axios';
import { API_URL_NEW } from '../lib/endpoints'


const teamApi = {
    getTeamsCount: (filters) => {
        return new Promise((resolve, reject) => {
            let url = `${API_URL_NEW}/team/setting/count?filters=${filters}`;
            axios.get(url).then(async (response) => {  
                if (response.data.status === 200) {
                    resolve(response.data.data);
                } else {
                    let error = new Error(response);
                    throw error;
                }
            }).catch(err => {
                let data = {
                    error: err,
                    // set static value in case of error
                    totalCount: "1,000"
                }
                reject(data);
            })
        })
    },
}

export default teamApi

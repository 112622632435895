import { PUBLIC_IMG_URL } from '/src/lib/endpoints';

export const CLIENT_LOGOS = [
    `${PUBLIC_IMG_URL}/logos/difc_logo.png`,
    `${PUBLIC_IMG_URL}/logos/sequoia-capital-logo.png`,
    `${PUBLIC_IMG_URL}/logos/aramco-logo.png`,
    `${PUBLIC_IMG_URL}/logos/global-ventures-logo.png`,
    `${PUBLIC_IMG_URL}/logos/world-bank-logo.png`,
    `${PUBLIC_IMG_URL}/logos/citibank-logo.png`
]

export const NEWS_REFERENCES_LOGOS = [
    { name: "AlArabia", logo: "/logos/alarabiya.png" },
    { name: "Gulf Business", logo: "/logos/gulf_business.png" },
    { name: "Financial Times", logo: "/logos/financial-times.webp" },
    { name: "Bloomberg", logo: "/logos/bloomberg.png" },
    { name: "CNN", logo: "/logos/cnn.png" },
    { name: "Entrepreneur", logo: "/logos/entrepreneur.png" },
    { name: "Crunchbase News", logo: "/logos/cb_news.png" },
    { name: "Khaleej Times", logo: "/logos/khaleej_times.png" },
    { name: "The National", logo: "/logos/the_national.png" },
    { name: "Wamda", logo: "/logos/wamda.png" },
    { name: "World Economic Forum", logo: "/logos/world_economic_forum.png" },
    { name: "Arab News", logo: "/logos/arab_news.webp" },
    { name: "Tech Crunch", logo: "/logos/tech_crunch.png" },
    { name: "Tech In Asia", logo: "/logos/tech_in_asia.webp" },
    { name: "The Wall Street Journal", logo: "/logos/the_wsj.png" }
];
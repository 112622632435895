import React, { Component } from "react";
import { connect } from 'react-redux';

class JoinCustomerBase extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isUserLoggedIn: false,
        }
    }

    componentDidMount = () => {
        const { user } = this.props

        if (user && user.is_loggedin) {
            this.setState({ isUserLoggedIn: true })
        }
    }

    register() {
        const headerRegister = document.getElementById('headerRegister');
        if (headerRegister) {
            headerRegister.click();
        }
    }

    render() {
        const {isUserLoggedIn} = this.state
        return (
            <div className="join-wrap">
                <div className="join-text">
					Join organisations including Big Tech, investors, media, government and consulting firms that use MAGNiTT every day
                </div>
                {!isUserLoggedIn && <a className="btn btn-main btn-lg" onClick={this.register}>Register for Free</a>}
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    user: state.userReducer
})

export default connect(mapStateToProps, null)(JoinCustomerBase);

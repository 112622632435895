import { API_URL_NEW } from '../lib/endpoints'
import axios from 'axios'

const demoApi = {
    sendDemoRequest: (payload) => {
        const config = {
            method: 'POST',
            url: `${API_URL_NEW}/demo-request`,
            data: payload
        };

        return new Promise((resolve, reject) => {
            axios(config)
                .then(async (res) => {
                    resolve(res)
                }).catch(err => reject(err))
        });
    }
}

export default demoApi;
import React, { Component } from 'react';
import { sharedLoggerApi } from 'shared-helpers';

// api
import exitApi from '/src/apis/exitApi.js';
import fundingApi from '/src/apis/fundingApi.js';
import startupApi from '/src/apis/startupApi.js';
import ecosystemApi from '/src/apis/ecosystemApi.js';
import teamApi from '/src/apis/teamApi.js';

// constants, helpers
import { TEAM_ROLE_TYPES } from '/src/lib/constants/teamRoles';
import { formatCount } from '/src/lib/helpers';

const LoggerApi = sharedLoggerApi;
const componentName = 'AnimatedEntityCounts';



export default class AnimatedEntityCounts extends Component {

    constructor(props) {
        super(props)
        this.state = {
            entityCount: 0,
        }
    }

    componentDidMount() {
        let countApi;

        if (this.props.countItem === "startups") {

            countApi = startupApi.getStartupsCount();
            this.setState({
                itemTitle: "Startups"
            });

        } else if (this.props.countItem === "ecosystems") {

            countApi = ecosystemApi.getEcosystemsCount();
            this.setState({
                itemTitle: "Investment Firms"
            });

        } else if (this.props.countItem === "funding-rounds") {

            countApi = fundingApi.getTotalFundingRoundsCount();
            this.setState({
                itemTitle: "Funding Rounds"
            });

        } else if (this.props.countItem === "exits") {

            countApi = exitApi.getExitsCount();
            this.setState({
                itemTitle: "Exits"
            });

        } else if (this.props.countItem === "founders") {

            let filters = `team_role_id^:${TEAM_ROLE_TYPES.FOUNDER}^;${TEAM_ROLE_TYPES.CO_FOUNDER}`;
            countApi = teamApi.getTeamsCount(filters);
            this.setState({
                itemTitle: "Founders"
            });

        } else if (this.props.countItem === "investors") {

            let filters = `entity_type^:INVESTOR^;CORPORATE^;ENABLER`;
            countApi = teamApi.getTeamsCount(filters);
            this.setState({
                itemTitle: "Investors"
            });

        }

        countApi.then(response => {
            this.animateCounts(response);
        }).catch(data => {
            // error, sets a default count
            this.setState({ entityCount: data.totalCount });
            LoggerApi.logError("getEntityCount-" + this.props.countItem, componentName, data.error)
        })

    }

    animateCounts = (count) => {
        let range = 25;
        let animDuration = 40; // ms
        let step = Math.abs(Math.floor(animDuration / range));
        let countStart = (count - range)
        let slowPoint = count - 5;
        let timer = setInterval(() => {
            countStart += 1;
            this.setState({ 
                entityCount: formatCount(countStart) 
            });
            if (countStart >= slowPoint) {
                clearInterval(timer);
                let slowTimer = setInterval(() => {
                    countStart += 1;
                    this.setState({ 
                        entityCount: formatCount(countStart) 
                    });
                    if (countStart == count) {
                        clearInterval(slowTimer);
                    }
                }, 100);
            }
        }, step);
    }

    render() {
        return (
            <div className="sort-box">
                <div className="sort-count">{this.state.entityCount}</div>
                <div>{this.state.itemTitle}</div>
            </div>
        )
    }

}

import demoApi from "/src/apis/demoApi";
import { isValidCompanyEmail, isValidEmail } from '/src/lib/helpers';



export const handleDemoRequest = async (email, hubspotFormSource) => {

    // validate email
    if (email === '') {
        return {
            type: 'error',
            message: 'Please enter your email'
        }
    } else if (!isValidEmail(email)) {
        return {
            type: 'error',
            message: 'Please enter a valid email address'
        }
    } else if (!isValidCompanyEmail(email)) {
        return {
            type: 'error',
            message: 'Please enter a company email address instead of a personal one'
        }
    }

    // handle request
    const payload = {
        email: email,
        page: hubspotFormSource
    };

    return new Promise((resolve, reject) => {
        demoApi.sendDemoRequest(payload).then(response => {
            if (response.status === 201) {
                resolve({
                    type: 'success',
                });
            }
        })
        .catch(err => {
            resolve({
                type: 'error',
                message: err.message
            })
        })
    })

}
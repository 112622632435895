/**
 * Team roles and their respective ids
 */
const teamRoleTypes = {
    development: {
        FOUNDER: 1,
        CO_FOUNDER: 10
    },
    production: {
        FOUNDER: 1,
        CO_FOUNDER: 10,
    }
}
export const TEAM_ROLE_TYPES = teamRoleTypes[process.env.NODE_ENV];
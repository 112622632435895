import React, { Component } from "react";
import Slider from 'react-slick';

// helpers, constants
import { PUBLIC_IMG_URL } from '../../lib/endpoints';



export default class Testimonials extends Component {
    constructor(props) {
        super(props)

        this.testimonials = [
            {
                author_name: "Luca Barbi",
                author_position: "General Partner & COO",
                author_img: "/enterprise/client_testimonials/stv_luca_barbi.jpg",
                company_name: "STV",
                logo: "/enterprise/client_testimonials/stv-logo-black.png",
                description: "MAGNiTT provides indispensable insights to define our investment strategy. We read through the quarterly VC reports to understand industry trends and dynamics across countries, sectors and investment stages.",
            },
            {
                author_name: "Mahmoud Nsouli",
                author_position: "Senior Vice President - Marketing & Corporate Communications",
                author_img: "/enterprise/client_testimonials/difc_mahmoud_nsouli.jpg",
                company_name: "DIFC",
                description: "MAGNiTT is a comprehensive platform that offers advanced, tailored solutions to DIFC. These solutions enable DIFC to navigate and understand the dynamic landscape of our startup ecosystem through its intuitive interface and powerful analytics across various regions, unlocking new insights.",
            },
            {
                author_name: "Sofiane Haddadi",
                author_position: "Client Partner for MENA Digital Services",
                author_img: "/enterprise/client_testimonials/tiktok_sofiane.png",
                company_name: "ByteDance/TikTok",
                logo: "/enterprise/client_testimonials/tiktok_logo.png",
                description: "Prior to using MAGNiTT, we relied on desk research, market intelligence, estimations. MAGNiTT has been instrumental in providing us one and unique reliable source of information when it comes to the startup industry in our region.",
            },
            {
                author_name: "Riccardo Zanella",
                author_position: "Vice President - Debt Investments",
                author_img: "/enterprise/client_testimonials/shuaa_capital_riccardo.png",
                company_name: "SHUAA Capital",
                description: "MAGNiTT provides our team with valuable insights that help support and drive investment decisions across the MENA Venture Debt market. We regularly use their data and reports to better understand areas of investor interest and capital flows across our target sectors, supplementing this insight with their data analytics tools.",
            },
            {
                author_name: "",
                author_position: null,
                author_img: "",
                company_name: "",
                logo: "/enterprise/client_testimonials/infomineo_logo.png",
                description: "We are using MAGNiTT to have a data-backed understanding on the venture markets in the MEA region. As a business research company, access to accurate and up-to-date information is very critical for our research projects. MAGNiTT's features and regular market intelligence reports are extremely helpful to have a clear view on the MENA startups ecosystem.",
            }
        ];

        this.settings = {
            dots: false,
            isfinite: false,
            speed: 500,
            slidesToShow: 3,
            slidesToScroll: 3,
            initialSlide: 0,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        dots: false
                    }
                },
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false
                    }
                }
            ],
            adaptiveHeight: true
        };
    }

    render() {
        return (
            <div className="testimonials-wrap">
                <Slider {...this.settings}>
                    {
                        this.testimonials.map((item, i) =>
                            <div key={i}>
                                <div className="testimonial-box">
                                    <div className="testimonial-content">

                                        <div className="d-flex align-items-center">
                                            <div className="flex-shrink-0">
                                                {item.author_img &&
                                                    <div>
                                                        <img className="author-img" src={`${PUBLIC_IMG_URL}${item.author_img}`} />
                                                    </div>
                                                }
                                                {!item.author_img && item.logo &&
                                                    <div className="logo-container">
                                                        <img className="logo img-fluid" src={`${PUBLIC_IMG_URL}${item.logo}`} />
                                                    </div>
                                                }
                                            </div>
                                            <div className="ml-3">
                                                {item.author_name && <h3 className="author-name">{item.author_name}</h3>}
                                                <h5 className="company-name">
                                                    {item.author_position &&
                                                        <>{item.author_position} at </>
                                                    }
                                                    {item.company_name}
                                                </h5>
                                            </div>
                                        </div>

                                        {/* review */}
                                        {item.description && 
                                            <p className="description">
                                                <span className="testimonial-quotemark">“</span>
                                                <p>{item.description}</p>
                                            </p>
                                        }

                                    </div>
                                </div>
                            </div>
                        )
                    }
                </Slider>
            </div>
        )
    }
}

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom';

// helpers, constants
import { PUBLIC_IMG_URL } from '../../lib/endpoints';
import { WEB_URL } from '../../lib/endpoints';
import { ALLOWED_QUERY_OPERATOR, HUBSPOT_FORM_SOURCE } from '../../lib/constants';
import { getItemWebUrl } from '../../lib/helpers';
import { prepareFilterParam, prepareSortParam } from '../../../src/lib/queryParams';
import { NEWS_REFERENCES_LOGOS } from "/src/lib/constants/logoConstants";
import { handleDemoRequest } from "/src/lib/helpers/demoRequestHelpers";

// reusable components
import EntityCounts from '../reusable/AnimatedEntityCounts';
import Testimonials from '../reusable/Testimonials';
import JoinCustomerBase from "../reusable/JoinCustomerBase";

// Apis
import researchApi from "../../apis/researchApi";



class LandingPage extends Component {
    constructor() {
        super();

        this.state = {
            // for demo request
            email: '',
            emailPlaceholder: '',
            success: false,
            emailError: false,

            // research reports
            researchReports: []
        };

        this.features = [
            { name: "Market Size", image: "/homepage/market_size.png" },
            { name: "Startup Landscape", image: "/homepage/startup_landscape.png" },
            { name: "Investor Ranking", image: "/homepage/investor_ranking.png" },
            { name: "Geographic Comparison", image: "/homepage/geographic_comparison.png" },
            { name: "Industry Ranking", image: "/homepage/industry_ranking.png" },
            { name: "Quarterly Trends", image: "/homepage/quarterly_trends.png" },
            { name: "Exit Tracking", image: "/homepage/exit_tracking.png" },
            { name: "Valuation Trends", image: "/homepage/valuation_trends.png" }
        ];
    }

    async componentDidMount() {
        // get research reports
        let filters = prepareFilterParam([{ key: 'is_published', value: [1], op: ALLOWED_QUERY_OPERATOR.equal }]);
        let sort = prepareSortParam([{ key: 'published_at', sortVal: 'desc' }]);

        researchApi.getResearchData(filters, 4, 1, sort).then((response) => {
            let research = response.records;
            for (let i in research) {

                // date
                if (research[i].published_at) {
                    let date = new Date(research[i].published_at)
                    let time = date.toLocaleString("en-US", { month: 'long', year: 'numeric' })
                    research[i].published_at = time;
                }

                // Price
                let price = "";

                if (research[i].type_id == 1) {
                    if (research[i].price > 0) {
                        price = "$" + research[i].price;
                    } else {
                        price = "Premium Report"; // (accessible through subscription)
                    }
                } else {
                    price = "FREE";
                }

                research[i].displayPrice = price;

            }
            this.setState({ researchReports: research })
        })
    }

    /* === register popup === */
    register() {
        const headerRegister = document.getElementById('headerRegister');
        if (headerRegister) {
            headerRegister.click();
        }
    }

    /* === demo request === */
    handleRequestDemo = async (e) => {
        const { email } = this.state;
        const hubspotFormSource = HUBSPOT_FORM_SOURCE.homepage_demo;

        handleDemoRequest(email, hubspotFormSource).then(response => {
            if (response.type === 'success') {
                this.setState({ 
                    email: '',
                    success: true,
                    emailError: false
                })
            } else if (response.type === 'error') {
                this.setState({ 
                    emailPlaceholder: response.message, 
                    emailError: true 
                })
            }
        })
    }

    render() {
        return (
            <div className="footer-margin-offset">
                <Helmet
                    title={'Home | MAGNiTT'}
                    link={[
                        {
                            rel: 'canonical',
                            href: `${WEB_URL}`
                        }
                    ]}
                    meta={[
                        {
                            name: 'description',
                            content: 'The number one source of analytics, data and insights covering startups, investors and funds in Middle East, Africa, Pakistan and Turkey.'
                        },
                        {
                            name: 'abstract',
                            content: 'The number one source of analytics, data and insights covering startups, investors and funds in Middle East, Africa, Pakistan and Turkey.'
                        },
                        {
                            property: 'og:type',
                            content: 'article'
                        },
                        {
                            property: 'og:url',
                            content: 'https://magnitt.com'
                        },
                        {
                            property: 'og:title',
                            content: 'Home | MAGNiTT'
                        }
                    ]}
                    script={[
                        {
                            'type': 'application/ld+json',
                            'innerHTML': `{
                                "@context": "http://schema.org",
                                "@type": "BreadcrumbList",
                                "itemListElement": [{
                                    "@type": "ListItem",
                                    "position": 1,
                                    "name": "home",
                                    "item": "${WEB_URL}"
                                }]
                            }`
                        }
                    ]}
                />

                <div className="home-landing-page footer-margin-offset">
                    {/* Main banner section */}
                    <section className="section-common section-banner">
                        <div className="banner-text">
                            The number one platform for verified venture capital data in the Middle East, Africa, Pakistan, and Turkey
                        </div>

                        <a className="btn btn-main btn-lg" onClick={this.register}>Start Your Free Trial Now</a>
                    </section>

                    {/* Client Logo Section */}
                    <section className="section-reference">
                        <div>We Are The Reference For</div>

                        <div className="logo-wrap">
                            {NEWS_REFERENCES_LOGOS.map((reference, i) => {
                                return (
                                    <div className="reference-logo" style={{ backgroundImage: `url(${PUBLIC_IMG_URL}${reference.logo})` }} key={i}>
                                    </div>
                                )
                            })}
                        </div>
                    </section>

                    {/* Directory Feature Section */}
                    <section className="section-feature">
                        <div className="feature-text">
                            Stay ahead of the curve on venture investments by using MAGNiTT for your benchmarking, due diligence, and trend analysis
                        </div>

                        <div className="feature-wrap row">
                            {this.features.map((feature, i) => {
                                return (
                                    <div className="feature col-xl-3 col-lg-4 col-md-6 col-sm-12" key={i}>
                                        <img src={`${PUBLIC_IMG_URL}${feature.image}`} className="feature-img"></img>
                                        <div className="red">{feature.name}</div>
                                    </div>
                                )
                            })}
                        </div>

                        <a className="btn btn-main btn-lg" href="/pricing">Explore Now</a>
                    </section>

                    {/* Demo Section */}
                    <section className="section-demo" style={{ backgroundImage: `linear-gradient(0deg, rgba(53, 63, 72, 0.8), rgba(53, 63, 72, 0.8)), url(${PUBLIC_IMG_URL}/homepage/demo_background.jpg)` }}>
                        <h1>Book a 15-minute Demo</h1>
                        <div className="demo-subtitle">
                            Find out how verified investment data, trends, and insights can amplify your daily business decisions
                        </div>

                        <div className='demo-input'>
                            <div className="input-group row m-0" >
                                <input type="text"
                                    className="form-control col-9 clear-outline"
                                    placeholder='Enter your email'
                                    value={this.state.email}
                                    onChange={(e) => this.setState({ email: e.target.value, emailError: false, emailPlaceholder: '' })} />
                                <a className="btn btn-link input-group-append staticpage-demo-btn col-3"
                                    onClick={this.handleRequestDemo}>
                                    Submit
                                </a>
                            </div>
                            {this.state.emailError && <div className="form-error">{this.state.emailPlaceholder}</div>}
                            {this.state.success && <div className="form-success">Your request was submitted, you will be contacted shortly!</div>}
                        </div>
                    </section>

                    {/* Directory Count Section */}
                    <section className="section-common section-count">
                        <div className="count-text">
                            Get accurate investor, startup, and funding data across emerging venture markets
                        </div>

                        <div className="count-wrap row">
                            <div className="count col-xl-4 col-md-6 col-12">
                                <EntityCounts countItem="startups" />
                            </div>
                            <div className="count col-xl-4 col-md-6 col-12">
                                <EntityCounts countItem="ecosystems" />
                            </div>
                            <div className="count col-xl-4 col-md-6 col-12">
                                <EntityCounts countItem="funding-rounds" />
                            </div>
                            <div className="count col-xl-4 col-md-6 col-12">
                                <EntityCounts countItem="exits" />
                            </div>
                            <div className="count col-xl-4 col-md-6 col-12">
                                <EntityCounts countItem="founders" />
                            </div>
                            <div className="count col-xl-4 col-md-6 col-12">
                                <EntityCounts countItem="investors" />
                            </div>
                        </div>
                    </section>

                    {/* Real Time Dashboard with Register*/}
                    <section className="section-dashboard">
                        <div>Access Real-Time Directories, Dashboards, and Reports</div>
                        <div className="new-slider">
                            <img src={`${PUBLIC_IMG_URL}/homepage/dashboard-img.png`} className="dashboard-img"></img>
                        </div>
                        <a className="btn btn-main btn-lg" onClick={this.register}>Register for Free</a>
                    </section>

                    {/* Client Testimonials */}
                    <section className="section-about section-testimonials">
                        <div className="mb-2">Our Clients Speak</div>
                        <Testimonials />
                    </section>

                    {/* Latest Reports Section */}
                    <section className="section-about section-reports">
                        <div className="header-with-line"><span>Latest Insights</span></div>

                        <div className="reports-wrap">
                            {this.state.researchReports.map((item, i) => {
                                let itemUrl = getItemWebUrl('research', item.id, item.code);

                                return (
                                    <div className={i >= 3 ? "col-sm-12 col-lg-6 hidden-sm-down" : "col-sm-12 col-lg-6"} key={i}>
                                        <div className="report-item" lang={item.lang}>
                                            <div className="row">
                                                <div className="col-md-5">
                                                    <a href={`/${itemUrl}`}><img src={item.image_data.path} /></a>
                                                </div>

                                                <div className="col-md-7">
                                                    <h6><a href={`/${itemUrl}`}>{item.name}</a></h6>
                                                    <div className="text-muted">{item.displayPrice} | {item.published_at}</div>
                                                    {/* desktop description */}
                                                    <div className="d-none d-sm-block report-list">
                                                        <div>{item.short_description}</div>
                                                        <div className="mt-2"><a href={`/${itemUrl}`} className="read-more">Read More...</a></div>
                                                    </div>
                                                </div>

                                                {/* mobile description */}
                                                <div className="col-12 d-block d-sm-none report-list">
                                                    <div>{item.short_description}</div>
                                                    <div className="mt-2"><a href={`/${itemUrl}`} className="read-more">Read More...</a></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>

                        <a className="red explore-all" href="/research">Explore All Insights</a>
                    </section>

                    {/* Join Section */}
                    <JoinCustomerBase />

                </div>
            </div>
        )
    }
}

export default withRouter(LandingPage)

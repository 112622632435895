import axios from 'axios';
import { prepareFilterParam, prepareSelectParam,  } from '../lib/queryParams'
import { API_URL_NEW } from '../lib/endpoints'
import { ALLOWED_QUERY_OPERATOR } from '../lib/constants';


const ecosystemApi = {
    getInvestors: (selectableFields, filters, limit, page, sort) => {
        const arSelect = ['id', 'name', 'ecosystem_type', 'code', 'owned_by', 'logo_data', ...selectableFields];
        const fields   = prepareSelectParam(arSelect);

        return new Promise((resolve, reject) => {
            let url = `${API_URL_NEW}/ecosystem/?fields=${fields}&filters=${filters}^,status^:1&limit=${limit}&page=${page}&sort=${sort}`
            axios.get(url)
                .then(response => {
                    if (response.data.status === 200) {
                        let investors = response.data.data;
                        let count = response.data.navigation.total
                        
                        resolve({
                            investors,
                            count
                        })
                    } else {
                        reject(response)
                    }
                }).catch(err => reject(err))
        })
    },

    getICESuggestions: (name = '') => {
        const fields = prepareSelectParam(['id', 'ecosystem_type', 'name']);

        const filters = prepareFilterParam([
            {
                key: 'name',
                value: [name],
                op: ALLOWED_QUERY_OPERATOR.substring
            },
            {
                key: 'status',
                value: [1],
                op: ALLOWED_QUERY_OPERATOR.equal
            }
        ]);

        return new Promise((resolve, reject) => {
            const url = `${API_URL_NEW}/ecosystem/?fields=${fields}&filters=${filters}&limit=5`
            axios.get(url)
                .then(response => {
                    resolve(response.data.data);
                }).catch(err => reject(err))
        });
    },

    getEcosystemsCount: () => {
        return new Promise((resolve, reject) => {
            let url = `${API_URL_NEW}/ecosystem/setting/count`;
            axios.get(url).then(async (response) => {  
                if (response.data.status === 200) {
                    resolve(response.data.data);
                } else {
                    let error = new Error(response);
                    throw error;
                }
            }).catch(err => {
                let data = {
                    error: err,
                    // set static value in case of error
                    totalCount: "7,500"
                }
                reject(data);
            })
        })
    }
}

export default ecosystemApi

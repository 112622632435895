import axios from 'axios';
import { API_URL_NEW } from '../lib/endpoints'
import { prepareSelectParam, prepareFilterParam } from '../lib/queryParams'
import { ALLOWED_QUERY_OPERATOR } from '../lib/constants';

const researchApi = {

    getResearchData: (filters, limit, page, sort) => {
        const fields = prepareSelectParam(['id', 'name', 'short_description', 'preview_image_data', 'image_data', 'code', 'sample_data', 'published_at', 'type_id', 'price', 'lang', 'catedgory_data', 'sponsor_logo_id', 'sponsor_logo_data', 'created_at'])

        const url = `${API_URL_NEW}/research?fields=${fields}&filters=${filters}^,is_published^:1&limit=${limit}&page=${page}&sort=${sort}`;

        return new Promise((resolve, reject) => {
            axios.get(url).then(response => {
                if (response.data.status === 200 && response.data.data) {
                    resolve({
                        records: response.data.data,
                    });
                } else {
                    resolve({});
                }
            }).catch((err) => reject(err));
        })
    },

    getResearch: (researchQuery) => {
        const url = `${API_URL_NEW}/research?${researchQuery}`;
        return axios.get(url)
    },

    getResearchCount: (filters = "") => {
        const url = `${API_URL_NEW}/research/setting/count?filters=${filters}^,is_published^:1`;

        return new Promise((resolve, reject) => {
            axios.get(url).then(response => {
                const resp = response.data.data;
                if (resp && resp.length > 0) {
                    resolve(resp[0].total);
                } else {
                    let error = new Error(response);
                    throw error;
                }
            }).catch(err => {
                let data = {
                    error: err,
                    // set static value in case of error
                    totalCount: "200"
                }
                reject(data);
            })
        })
    },

    getPromotedResearchData: (researchId) => {
        const fields = prepareSelectParam(["promoted_research_data"])
        const filters = prepareFilterParam([{key: 'id', value: [researchId], op: ALLOWED_QUERY_OPERATOR.equal }])

        const url = `${API_URL_NEW}/research?fields=${fields}&filters=${filters}`;

        return new Promise((resolve, reject) => {

            axios.get(url).then((response) => {
                if (response.data.status === 200 && response.data.data.length > 0) {
                    resolve({
                        record: response.data.data[0].promoted_research_data,
                    });
                } else {
                    resolve({});
                }
            }).catch((err) => reject(err));
        });
    },

}

export default researchApi;